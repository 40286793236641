import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import LandingPage from "./pages/LandingPage";
import "./styles/App.scss";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
  ]);

  return (
    <ToastProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ToastProvider>
  );
}

export default App;
