import axios from "axios";

function joinWaiting(data, callBack) {
  axios({
    method: "GET",
    url: `https://5920qlxr5l.execute-api.us-east-1.amazonaws.com/Prod/guest?email=${data.email}&company=PT`,
  })
    .then((res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      // return callBack(err)
      console.log(`An error occured - `, err);
      return callBack(err?.response?.data);
    });
}

export const services = {
  joinWaiting,
};
