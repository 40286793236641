import React, { useEffect, useState } from "react";
import "../styles/LandingPage.scss";
import { Button } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useToasts } from "react-toast-notifications";
import {
  faPhone,
  faMapMarked,
  faEnvelope,
  faMobile,
  faExchange,
  faBook,
  faSackDollar,
  faHandHoldingDollar,
  faPlug,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHtmlParser from "react-html-parser";
import { services } from "../services";

function validateEmail(email) {
  let re = /\S+@\S+\.\S+/;

  if (re.test(email)) {
    if (email.substring(email.length - 11, email.length) === "detectu.com") {
      return false;
    }
    return true;
  }
}

const LinkItemView = ({ className, tag, specialHtml }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const { addToast } = useToasts();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }

    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const variants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const openLink = (event) => {
    let clickedTag = event.target.innerText;

    switch (clickedTag) {
      case "- Marketplace -":
        addToast("Coming soon", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          transitionDuration: 100,
        });
        break;

      case "+254713278107":
        navigator.clipboard.writeText(event.target.innerText);
        addToast("Copied Phone No. to clipboard", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 100,
        });
        break;

      case "pesatoken@gmail.com":
        navigator.clipboard.writeText(event.target.innerText);
        addToast("Copied email to clipboard", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
          transitionDuration: 100,
        });
        break;

      default:
        break;
    }
  };

  return (
    <motion.div
      ref={ref}
      className="link-motiondiv"
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      {specialHtml?.length > 0 ? null : (
        <p className={className} onClick={openLink}>
          {tag}
        </p>
      )}

      {specialHtml?.length > 0 ? (
        <p className={className} onClick={openLink}>
          {ReactHtmlParser(specialHtml) + " "}
          {tag}
        </p>
      ) : null}
    </motion.div>
  );
};

const PropView = ({ mainProp, explainer, captureEmail, joinWaitingList }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const screenWidth = window.innerWidth;

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      controls.start("positioned_left");
    }

    if (!inView) {
      controls.start("hidden");
      controls.start("revert_right");
    }
  }, [controls, inView]);

  const imgVariants = {
    hidden: { scale: 0 },
    revert_right: {
      x: -100,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    positioned_left: {
      x: 20,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="value-motiondiv"
      initial="hidden"
      animate={controls}
      variants={imgVariants}
    >
      <div className="value-prop">
        <p className="landing-value-prop">
          <strong className="value-prop-main">{mainProp}</strong>
        </p>
        <p className="landing-value-explainer">{explainer}</p>
        <p className="landing-value-explainer">
          Join our waitlist to be notified when we launch.
        </p>

        <input
          type="text"
          onChange={captureEmail}
          placeholder="address@domain.com"
          className="value-email-input"
          id="emailfield"
        ></input>

        <Button
          className="btn-waiting-list"
          variant="contained"
          onClick={joinWaitingList}
        >
          JOIN
        </Button>
      </div>
    </motion.div>
  );
};

const ImageView = ({ src, className, alt }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const screenWidth = window.innerWidth;

  useEffect(() => {
    if (inView) {
      controls.start("visible");

      if (className === "start-img-2") {
        controls.start("positioned_left");
      } else if (className === "start-img") {
        controls.start("positioned");
      }
    }

    if (!inView) {
      controls.start("hidden");

      if (className === "start-img-2") {
        controls.start("revert_left");
      } else if (className === "start-img") {
        controls.start("revert_right");
      }
    }
  }, [controls, inView]);

  const imgVariants = {
    hidden: { scale: 0 },
    revert_left: {
      x: 90,
    },
    revert_right: {
      x: -100,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    positioned: {
      x: screenWidth > 1099 ? 250 : 100,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
    positioned_left: {
      x: screenWidth > 1099 ? -150 : -90,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="start-img-motiondiv"
      initial="hidden"
      animate={controls}
      variants={imgVariants}
    >
      <img src={src} className={className} alt={alt}></img>
    </motion.div>
  );
};

const ValueView = ({ icon, title, text }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const screenWidth = window.innerWidth;

  useEffect(() => {
    if (inView) {
      controls.start("visible");

      switch (title) {
        case "USSD payments":
          controls.start("positioned_left");
          break;

        case "Spend Freely":
          controls.start("positioned");
          break;

        case "Buy and Sell":
          controls.start("positioned_left");
          break;

        case "Manage and Track expenses":
          controls.start("positioned");
          break;

        case "Invest and Earn":
          controls.start("positioned_left");
          break;

        case "Borrow and Grow":
          controls.start("positioned");
          break;

        case "Automate via APIs":
          controls.start("positioned_left");
          break;

        default:
          break;
      }
    }

    if (!inView) {
      controls.start("hidden");

      switch (title) {
        case "USSD payments":
          controls.start("revert_left");
          break;

        case "Spend Freely":
          controls.start("revert_right");
          break;

        case "Buy and Sell":
          controls.start("revert_left");
          break;

        case "Manage and Track expenses":
          controls.start("revert_right");
          break;

        case "Invest and Earn":
          controls.start("revert_left");
          break;

        case "Borrow and Grow":
          controls.start("revert_right");
          break;

        case "Automate via APIs":
          controls.start("revert_left");
          break;

        default:
          break;
      }
    }
  }, [controls, inView]);

  const imgVariants = {
    hidden: { scale: 0 },
    revert_left: {
      x: 90,
    },
    revert_right: {
      x: -100,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    positioned: {
      x: screenWidth > 1099 ? 0 : 40,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
    positioned_left: {
      x: screenWidth > 1099 ? 0 : 40,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="value-motiondiv"
      initial="hidden"
      animate={controls}
      variants={imgVariants}
    >
      <div className="value-service">
        <div className="value-service-header">
          <FontAwesomeIcon icon={icon} size="2x"></FontAwesomeIcon>
        </div>
        <div className="value-service-title">
          <p className="title-inner">{title}</p>
        </div>
        <div className="value-service-content">
          <p className="content-inner">{text}</p>
        </div>
      </div>
    </motion.div>
  );
};

function LandingPage() {
  const [email, setEmail] = useState("");

  const { addToast } = useToasts();

  const captureEmail = (event) => {
    let emailAddress = event.target.value;
    if (validateEmail(emailAddress)) setEmail(emailAddress);
  };
  const comingSoon = () => {
    addToast("Coming Soon. Join waitlist to get notified.", {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 2000,
      transitionDuration: 100,
    });
  };

  const joinWaitingList = () => {
    if (email) {
      services.joinWaiting({ email }, (response) => {
        // console.log(response);
        if (response?.message === "success")
          return addToast("Success", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
            transitionDuration: 100,
          });
        addToast(response?.message, {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          transitionDuration: 100,
        });
      });
    } else {
      addToast("Please enter email", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        transitionDuration: 100,
      });
    }
  };

  const screenWidth = window.innerWidth;

  return (
    <div className="landing">
      <div className="landing-header">
        <div className="landing-logo-div">
          <ImageView
            src={
              "https://pt-landing-images.s3.amazonaws.com/croppedlogo-pt.jpeg"
            }
            className="logo"
            alt="pesa logo"
          />
          <Button
            className="landing-btn-login"
            variant="contained"
            onClick={comingSoon}
          >
            LOGIN
          </Button>
        </div>
      </div>
      <div className="landing-start">
        <div className="landing-value-0">
          <PropView
            mainProp={"Save and Spend Bitcoin with Pesa Token"}
            explainer={
              "Spend crypto easily and securely at your local supermarket, barbershop, liquor store, butchery or mama mboga via our app with minimal fees."
            }
            captureEmail={captureEmail}
            joinWaitingList={joinWaitingList}
          />

          <ImageView
            src={
              "https://pt-landing-images.s3.amazonaws.com/girl-w-p-3-removebg-pt.png"
            }
            className="start-img"
            alt="phone-tech"
          />
        </div>

        <p className="coming-soon">(Coming Soon)</p>
        <div className="landing-value-1">
          <div className="landing-value-start">
            <p className="landing-value-prop">
              <strong className="value-prop-main">
                Pay and Get Paid with Bitcoin
              </strong>
            </p>
          </div>
        </div>

        <div className="landing-value-4">
          <input
            type="text"
            onChange={captureEmail}
            placeholder="address@domain.com"
            className="value-email-input"
            id="emailfield"
          ></input>

          <Button
            className="btn-waiting-list"
            variant="contained"
            onClick={joinWaitingList}
          >
            JOIN
          </Button>

          {screenWidth > 1100 ? (
            <p className="landing-value-header">
              Get started with De-Fi on Pesa Token
            </p>
          ) : null}
        </div>

        <div className="landing-value-2">
          <p className="landing-value-header">
            Get started with De-Fi on Pesa Token
          </p>
          <ValueView
            icon={faMobile}
            title={"USSD payments"}
            text={
              "We provide 'offline' access to your crypto wallet via a USSD shortcode. Utilize your funds with a basic GSM-enabled mobile phone or in areas with limited internet reception."
            }
          />

          <ValueView
            icon={faMoneyBillWave}
            title={"Spend Freely"}
            text={
              "We provide versatile options to spend your cryptocurrency. Pay for regular day-to-day goods and services such as food, fuel, car parts, cooking gas, barbershop or salon and so much more using Pesa Token."
            }
          />

          <ValueView
            icon={faExchange}
            title={"Buy and Sell"}
            text={
              "We provide a secure and anonymous P2P marketplace, find the best offers for popular cryptos such as Bitcoin and Ethereum. Place an offer to sell your crypto at your desired price."
            }
          />

          <ValueView
            icon={faBook}
            title={"Manage and Track expenses"}
            text={
              "Our AI-powered dashboard equipped with cashflow analytics enables you to keep track of each transaction and manage your spending habits to meet your financial goals."
            }
          />

          <ValueView
            icon={faSackDollar}
            title={"Invest and Earn"}
            text={
              "We provide a staking platform to deposit funds for a specific period of time. Earn a yield of up-to 10% APY."
            }
          />

          <ValueView
            icon={faHandHoldingDollar}
            title={"Borrow and Grow"}
            text={
              "We provide credit support to SMEs, either in fiat money or crypto, at fair affordable rates, to enable you reach your goals."
            }
          />

          <ValueView
            icon={faPlug}
            title={"Automate via APIs"}
            text={
              "We provide a fast, secure and reliable API with 99% uptime. Integrate to work with other digital financial products and enable crypto payments."
            }
          />
        </div>

        <div className="landing-value-3">
          <p className="value-prop">
            We're still under construction, you'll get an email once we're
            ready.
          </p>
        </div>

        <div className="landing-value-4">
          <input
            type="text"
            onChange={captureEmail}
            placeholder="address@domain.com"
            className="value-email-input"
            id="emailfield"
          ></input>

          <Button
            className="btn-waiting-list"
            variant="contained"
            onClick={joinWaitingList}
          >
            JOIN WAITING-LIST
          </Button>
        </div>

        <div className="landing-footer">
          <div className="landing-footer-contact">
            <p className="footer-title">Contact Us</p>

            <div className="contact-item">
              <FontAwesomeIcon
                icon={faPhone}
                className="tel-icon"
              ></FontAwesomeIcon>
              <LinkItemView className={"tel"} tag={"+254713278107"} />
            </div>

            <div className="contact-item">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="email-icon"
              ></FontAwesomeIcon>
              <LinkItemView className={"email"} tag={"pesatoken@gmail.com"} />
            </div>

            <div className="contact-item">
              <FontAwesomeIcon
                icon={faMapMarked}
                className="location-icon"
              ></FontAwesomeIcon>
              <LinkItemView className={"location"} tag={"Nairobi, Kenya"} />
            </div>
          </div>
          <div className="footer-lower">
            <LinkItemView
              className={"copyright"}
              tag={"2024 Pesa Token. All Rights Reserved"}
              specialHtml={"&#169;"}
            />

            <ImageView
              src={
                "https://pt-landing-images.s3.amazonaws.com/croppedlogo-pt.jpeg"
              }
              className="footer-logo"
              alt="pesa logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
